import React from 'react'
import styled from 'styled-components'
import ParseContent from '../shared/ParseContent'
import ButtonDefault from './ButtonDefault'

const TextLinkSection = styled.section`
  h2 {
    font-size: 50px;
    font-weight: 500;
  }

  padding-bottom: 5rem;

  @media (max-width: 991.98px) {
    padding-top: 3rem;
  }
`

function TextLink({ title, description, link }) {
  return (
    <TextLinkSection>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto py-lg-3">
            {title && <h2 className="text-left">{title}</h2>}
            {description && (
              <div className="text-justify">
                <ParseContent content={description} />
              </div>
            )}
          </div>
        </div>
        {link && (
          <div className="d-flex justify-content-center">
            <ButtonDefault to={link.url}>{link.title}</ButtonDefault>
          </div>
        )}
      </div>
    </TextLinkSection>
  )
}

export default TextLink
