import Plaatjie from '@ubo/plaatjie'
import React from 'react'
import styled from 'styled-components'
import ParseContent from '../shared/ParseContent'
import ButtonDefault from './ButtonDefault'

const TeammembersSection = styled.section`
  .teammember-content {
    max-width: 50ch;

    h3 {
      font-family: ${(props) => props.theme.font.family.secondary};
      text-transform: uppercase;
      font-weight: 500;
      font-size: 30px;
    }

    h4 {
      color: #d50021;
      font-weight: 300;
      font-size: 20px;
    }

    p {
      margin-top: 2rem;
      font-size: 17px;
      text-align: justify !important;
    }
  }

  @media (min-width: 992px) {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
`

const TeammemberImage = styled(Plaatjie)`
  max-width: 200px;

  @media (max-width: 991.98px) {
    margin-bottom: 1rem;
  }
`

function Teammembers({ description, teammembers }) {
  return (
    <TeammembersSection id="teamleden">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="mb-lg-5">
              <ParseContent content={description} />
            </div>

            {teammembers.map((edge, index) => {
              const odd = index % 2 === 0
              return (
                <div
                  className={`align-items-center row mb-5 pb-lg-5 ${
                    odd ? '' : 'flex-row-reverse'
                  }`}
                >
                  <div className="col-sm-6 col-lg-4 px-3 h-100">
                    <div className="d-flex flex-column justify-content-center h-100 align-items-lg-center">
                      <TeammemberImage image={edge.image} />
                    </div>
                  </div>
                  <div
                    className={`col-sm-6 col-lg-8 d-flex ${
                      odd ? 'pl-lg-5' : 'pr-lg-5'
                    }`}
                  >
                    <div className="teammember-content">
                      <h3>{edge.name}</h3>
                      <h4>{edge.jobtitle}</h4>
                      <ParseContent content={edge.description} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="row justify-content-center mb-5 mb-lg-5">
          <div className="d-flex flex-column align-items-center">
            <h2>Deel uitmaken van ons team?</h2>
            <ButtonDefault to="/vacatures/">
              Bekijk onze vacatures
            </ButtonDefault>
          </div>
        </div>
      </div>
    </TeammembersSection>
  )
}

export default Teammembers
