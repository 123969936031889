/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'
import ParseContent from '../shared/ParseContent'
import BoxDefault from './BoxDefault'
import ButtonDefault from './ButtonDefault'
import { mainTheme } from '../../styles/Theme'

const TextLinkSection = styled.section`
  h3 {
    text-align: left;
    text-transform: uppercase;
    margin-top: 45px;
    font-family: 'Saira Extra Condensed', Arial, Verdana, sans-serif;
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 110%;
      margin-bottom: 25px;
    }
  }

  h2 {
    font-size: 50px;
    font-weight: 500;
  }

  padding-bottom: 5rem;

  @media (max-width: 991.98px) {
    padding-top: 3rem;
  }
`

const StyledBoxDefault = styled(BoxDefault)`
  max-width: 1000px;
  line-height: 100%;

  * {
    color: #fff !important;
    text-align: left !important;
  }

  p {
    text-align: justify !important;
    padding-right: 2rem;
  }

  &:after {
    border-top-width: 70px;
    border-right-width: 70px;
  }

  h3 {
    font-size: 22px;
  }

  @media (min-width: 992px) {
    margin-top: -6rem;
    padding-block: 3rem;

    h2 {
      font-size: 50px;
      width: 70%;
      font-weight: 500;
    }
  }

  @media (max-width: 991px) {
    padding-block: 3rem;
  }
`

const StyledButtonDefault = styled(ButtonDefault)``

function HeroIntro({
  title,
  description,
  extradescription,
  titleunder,
  link,
  extraLink,
}) {
  return (
    <>
      <StyledBoxDefault className="container mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-11 text-left">
            <h2 className="mb-3 text-center">{title}</h2>
            <div className="text-justify py-4 py-lg-0">
              <ParseContent content={description} />
            </div>
            {(titleunder || link) && (
              <div className="row align-items-center mt-2 mt-lg-5 mr-lg-5 pr-lg-5">
                {titleunder && titleunder !== '-' && (
                  <div className="col-lg-7">
                    <h3 className="mb-0 font-weight-100 text-white">
                      {titleunder}
                    </h3>
                  </div>
                )}

                {link && (
                  <div
                    className={`${
                      titleunder === '-'
                        ? 'justify-content-lg-start col-lg-5 '
                        : extraLink
                        ? 'justify-content-lg-start col-lg-4'
                        : 'justfiy-content-lg-end col-lg-5 '
                    } d-flex mt-3 mt-lg-0 hiero-button
                  `}
                  >
                    <ButtonDefault
                      wedgeColor={mainTheme.color.main}
                      to={link.url}
                      target={link.target}
                    >
                      {link.title}
                    </ButtonDefault>
                  </div>
                )}
                {extraLink && (
                  <div className="justify-content-lg-start col-lg-5 d-flex mt-3 mt-lg-0">
                    <StyledButtonDefault
                      wedgeColor={mainTheme.color.main}
                      to={extraLink.url}
                      target={extraLink.target}
                    >
                      {extraLink.title}
                    </StyledButtonDefault>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </StyledBoxDefault>
      {extradescription && (
        <TextLinkSection>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto py-lg-3">
                {extradescription && (
                  <div className="text-justify">
                    <ParseContent content={extradescription} />
                  </div>
                )}
              </div>
            </div>
            {link && (
              <div className="d-flex justify-content-center mt-4">
                <ButtonDefault to="/contact/">Neem contact op</ButtonDefault>
              </div>
            )}
          </div>
        </TextLinkSection>
      )}
    </>
  )
}

export default HeroIntro
