/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
// import styled from "styled-components";

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ConceptItem from 'components/elements/ConceptItem'
// import ButtonDefaultOutline from 'components/elements/ButtonDefaultOutline'
// import Projecten from 'components/Projecten'
import Hero from '../components/elements/Hero'
import HeroIntro from '../components/elements/HeroIntro'
import TextLink from '../components/elements/TextLink'
import Teammembers from '../components/elements/Teammembers'

// const Button = styled(ButtonDefaultOutline)`
//   max-width: 300px;
//   margin-left: 21px;
// `

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageAbout {
        headerabout {
          titleAbout
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        intro {
          title
          description
          link1 {
            title
            url
          }
          link2 {
            title
            url
          }
        }

        powerOff {
          item {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1010, quality: 100)
                }
              }
            }
            title
            description
          }
        }

        projects {
          title
          buttonText
          buttonUrl
          project {
            ... on WpProject {
              id
            }
          }
        }

        history {
          title
          description
          link {
            title
            url
          }
        }

        team {
          description
          teammembers {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1010, quality: 100)
                }
              }
            }
            name
            jobtitle
            description
          }
        }

        cooperation {
          title
          description
        }
      }

      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { pageAbout, seo },
  },
}) => (
  <Layout subFooter>
    {seo && <SEO seo={seo} />}

    <Hero
      title={pageAbout.headerabout.titleAbout}
      src={pageAbout.headerabout.image}
    />

    <HeroIntro
      title={pageAbout.intro.title}
      description={pageAbout.intro.description}
      link={pageAbout.intro.link1}
      extraLink={pageAbout.intro.link2}
    />

    {pageAbout.powerOff.item.map((item, index) => (
      <ConceptItem right={index % 2 !== 0} key={index} item={item} />
    ))}

    <TextLink
      title={pageAbout.history.title}
      description={pageAbout.history.description}
      link={pageAbout.history.link}
    />

    <Teammembers
      description={pageAbout.team.description}
      teammembers={pageAbout.team.teammembers}
    />

    {/* <section className="pb-lg-5 mb-5 mb-lg-0">
      <div className="container pb-lg-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-12 d-flex flex-wrap justify-content-center mb-5 mb-lg-0">
            <h2 className="text-center mb-5 mb-lg-0">{pageAbout.projects.title}</h2>
            <Button to={pageAbout.projects.buttonURL}>
              {pageAbout.projects.buttonText}
            </Button>
          </div>
        </div>
        <div className="row">
          <Projecten projectIds={pageAbout.projects.project.map(id => id)} limit={2} />
        </div>
      </div>
    </section> */}
    {/* <section>
      <LogoSlider
        title={pageAbout.cooperation.title}
        description={pageAbout.cooperation.description}
      />
    </section> */}
  </Layout>
)

export default PageTemplate
