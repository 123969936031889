/* eslint-disable react/function-component-definition */
import React from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from '../shared/ParseContent'

// Images
import Fill from '../../img/Fill-1.svg'
import ChevronRightIcon from '../../img/icons/chevron-right.svg'

const ConceptItem = ({ item, right, onClickImage, timelaps }) => {
  const ConceptSection = styled.div`
    position: relative;

    @media (max-width: 991px) {
      margin-bottom: 5rem !important;
    }
  `

  const ConceptCol = styled.div`
    position: relative;

    ${(props) =>
      props.right
        ? css`
            order: 2;
          `
        : css`
            /* DO NOTHING */
          `}

    @media (max-width: 991px) {
      position: absolute;
    }
  `
  const ConceptImage = styled(Plaatjie)`
    width: 100%;
    height: 100%;
    min-height: 813px;
    object-fit: cover;
  `

  const ConceptImageArticle = styled(Plaatjie)`
    @media (max-width: 991px) {
      height: 450px;
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 767px) {
      height: 375px;
      width: 100%;
      object-fit: cover;
    }

    @media (max-width: 575px) {
      height: 325px;
      width: 100%;
      object-fit: cover;
    }
  `

  const ConceptRow = styled.div`
    position: relative;

    ${(props) =>
      props.right
        ? css`
            justify-content: flex-end;
          `
        : css`
            /* DO NOTHING */
          `}
  `

  const ConceptText = styled.div`
    z-index: 1;
    min-width: 481px;

    & p {
      font-size: 14px !important;
    }

    ${(props) =>
      props.right
        ? css`
            margin-right: -13rem;
          `
        : css`
            margin-left: -13rem;
          `}

    @media (min-width: 992px) {
      background: url(${Fill}) no-repeat;
      background-size: contain;

      height: 641px;
      max-width: 480px;
    }

    @media (max-width: 991px) {
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid ${(props) => props.theme.color.contrast};

      min-width: auto;
      margin: 0 1rem;
    }

    @media (max-width: 472px) {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  `

  const TimeLapsButton = styled.a`
    display: inline-block;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    transition: transform 0.25s ease 0s;

    &:hover {
      transform: scale(1.05);
      color: #d50032;
    }

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      //border-top: 16.5px solid transparent;
      border-top: 1rem solid transparent;
      //border-right: 15px solid #fff;
      border-right: 1rem solid ${(props) => props.wedgeColor};
      background: transparent;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    & span {
      text-decoration: unset;
      background-color: #d50032;
      color: #ffffff;
      padding: 10px 15px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 0;
      position: relative;

      &::after {
        content: '';
        display: block;
        background-image: url(${ChevronRightIcon});
        background-size: contain;
        width: 14px;
        height: 18px;
        background-repeat: no-repeat;
        margin-left: 20px;
        margin-right: 5px;
      }

      &:hover {
      }
    }
  `

  return (
    <ConceptSection className="d-flex align-items-center my-5">
      <ConceptCol className="col-lg-7 p-0 d-none d-lg-block" right={right}>
        {onClickImage ? (
          <div
            onClick={onClickImage}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <ConceptImage image={item.image} />
          </div>
        ) : (
          <ConceptImage image={item.image} />
        )}
      </ConceptCol>
      <div className="container">
        {onClickImage ? (
          <div
            onClick={onClickImage}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <ConceptImageArticle className="d-lg-none" image={item.image} />
          </div>
        ) : (
          <ConceptImageArticle className="d-lg-none" image={item.image} />
        )}
        <ConceptRow right={right} className="row">
          <ConceptText
            right={right}
            className="col-lg-7 d-flex align-items-start py-5"
          >
            <div className="text w-100">
              {item.title && <ParseContent content={item.title} />}
              {item.titleHTML && item.titleHTML}
              <div className="text-justify px-lg-4">
                {item.description && (
                  <ParseContent content={item.description} />
                )}
                {item.descriptionHTML && item.descriptionHTML}
                {timelaps && timelaps.iframe !== null && (
                  <TimeLapsButton
                    href={timelaps.iframe}
                    target="_blank"
                    className="mt-4"
                  >
                    <span>Bekijk timelaps</span>
                  </TimeLapsButton>
                )}
              </div>
            </div>
          </ConceptText>
        </ConceptRow>
      </div>
    </ConceptSection>
  )
}

export default ConceptItem
